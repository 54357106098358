<template>
  <div className="home font-mono">
    <home-clinics-component :isCell="isCell" />
    <div className="h-10" />
    <home-speciality-component :isCell="isCell" />
    <div className="h-10" />
    <home-day-hospital-component />
    <div className="h-10" />
  </div>
</template>

<script>
import HomeClinicsComponent from '../components/home/HomeClinicsComponent.vue';
import HomeSpecialityComponent from '../components/home/HomeSpecialityComponent.vue';
import HomeDayHospitalComponent from '../components/home/HomeDayHospitalComponent.vue';

export default {
  name: 'HomeView',
  components: {
    HomeClinicsComponent,
    HomeDayHospitalComponent,
    HomeSpecialityComponent,
  },
  data() {
    return {
      isCell: false,
      windowWidth: window.innerWidth,
    };
  },
  methods: {
    onResize() {
      this.windowWidth = window.innerWidth;
    },
    verifyResize(i) {
      if (i < 768) {
        return true;
      } return false;
    },
  },
  watch: {
    windowWidth(newWidth) {
      this.isCell = this.verifyResize(newWidth);
    },
  },
  beforeMount() {
    this.isCell = this.verifyResize(window.innerWidth);
  },
  mounted() {
    this.$nextTick(() => {
      window.addEventListener('resize', this.onResize);
    });
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.onResize);
  },
};
</script>

<template>
  <div>
    <div class="bg-white h-2 w-[85%] mx-auto my-3 rounded-md" />
    <img alt="" className="max-h-[4rem] m-auto" src="../../assets/images/logo.png" />
    <div class="bg-white h-0.5 w-[85%] mx-auto my-3 rounded-md" />
  </div>
</template>

<script>
export default {
  name: 'PartOne',
};
</script>

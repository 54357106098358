<!-- eslint-disable vue/max-len -->
<!-- eslint-disable max-len -->
<template>
  <div className="home-about-component max-w-[34rem] px-2 m-auto">
    <div className="font-bold text-left text-2xl">
      Hospital Dia Angimedi:
    </div>
    <div className="text-justify">
      <div className="my-2">
        O Hospital dia, localizado na nossa unidade de São José dos Pinhais, foi criado para prestar atendimento aos pacientes que necessitam de uma assistência intermediária entre o ambulatório e um hospital integral e atende pacientes particulares e conveniados.
      </div>
      <div className="my-2">
        Contamos com uma competente equipe treinada para providenciar o melhor acolhimento para o paciente, com médicos, psicólogos, promovendo grupos terapêuticos e um espaço aconchegante de tratamento.
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'HomeAboutComponent',
};
</script>

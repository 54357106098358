<template>
  <div>
    <div class="bg-white h-2 w-[85%] mx-auto my-3 rounded-md" />
    <div class="text-2xl font-bold mb-3">
      Endereço
    </div>
    <div class="text-sm">
      <strong>Sede Curitiba: </strong> Rua Mateus Leme, 289, Loja 1 (Térreo)<br />
      <strong>Sede São José dos Pinhais: </strong> Av. das Americas, 737, Lojas 1, 2 e 3 (Térreo).
    </div>
    <div class="bg-white h-[1px] w-[80%] mx-auto my-3 rounded-md" />
    <div class="text-sm">
      <strong>Telefone (WhatsApp/Curitiba): </strong> +55 41 99500-8877
    </div>
    <div class="text-sm">
      <strong>Telefone (WhatsApp/São José dos Pinhais): </strong> +55 41 99222-1174
    </div>
  </div>
</template>

<script>
export default {
  name: 'PartThree',
};
</script>

<!-- eslint-disable vue/max-len -->
<!-- eslint-disable max-len -->
<template>
  <div class="about-history-component">
    <div className="max-w-[34rem] px-2 m-auto">
      <div className="font-bold text-left text-2xl">
        Sobre Psiquiatria Forense:
      </div>
      <div className="text-justify">
        <div className="my-2">
          A Psiquiatria Forense é um campo especializado que une a expertise da psiquiatria com o contexto legal. Ela se
          concentra na aplicação dos princípios psiquiátricos em situações jurídicas, ajudando a compreender e avaliar
          questões relacionadas à saúde mental dentro do sistema jurídico.
        </div>
        <div className="my-2">
          Os psiquiatras forenses desempenham um papel crucial em diferentes áreas legais, incluindo avaliação de
          capacidade mental, determinação de competência para enfrentar um julgamento, análise de responsabilidade penal e
          avaliação de danos psicológicos em processos civis.
        </div>
        <div className="my-2">
          Um dos aspectos fundamentais da Psiquiatria Forense é a avaliação psiquiátrica. Os psiquiatras forenses realizam
          atividades minuciosas para compreender o estado mental de um indivíduo em relação a questões legais específicas.
          Isso pode incluir a avaliação de transtornos mentais, a influência desses transtornos nas ações de um indivíduo
          e a compreensão de como essas condições podem afetar sua capacidade de compreender e participar de processos
          legais.
        </div>
        <div className="my-2">
          Por exemplo, em casos de determinação de capacidade mental, os psiquiatras forenses avaliam se um indivíduo
          possui a capacidade de compreender a natureza das acusações contra ele e de participar de sua própria defesa em
          um julgamento. Eles também podem ser solicitados a determinar se um indivíduo sofreu algum transtorno mental no
          momento de um crime, o que pode afetar a responsabilidade penal e as decisões sobre sentenças.
        </div>
        <div className="my-2">
          Além disso, os psiquiatras forenses podem ser consultados para fornecer depoimentos especializados em tribunais,
          prestando esclarecimentos sobre questões relacionadas à saúde mental e ajudando a explicar as descobertas de
          suas avaliações aos profissionais jurídicos.
        </div>
        <div className="my-2">
          É importante ressaltar que os psiquiatras forenses devem seguir diretrizes éticas rigorosas, fornecidas
          avaliações imparciais e baseadas em evidências, mantendo a imparcialidade e a objetividade em suas conclusões.
        </div>
        <div className="my-2">
          A Psiquiatria Forense desempenha um papel fundamental no sistema jurídico, oferecendo uma compreensão
          especializada das questões mentais em um contexto jurídico. Essa interseção entre psiquiatria e lei visa
          garantir que os aspectos psicológicos sejam considerados considerados na aplicação da justiça, promovendo assim
          decisões mais informadas e equitativas no sistema legal.
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AboutHistoryComponent',
};
</script>

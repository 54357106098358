<!-- eslint-disable vue/max-len -->
<!-- eslint-disable max-len -->
<template>
  <div class="about-text-component">
    <div className="max-w-[34rem] px-2 m-auto">
      <div className="font-bold text-left text-2xl">
        Sobre:
      </div>
      <div className="text-justify">
        <div className="my-2">
          A Angimedi Clínica Médica tem sido uma instituição emblemática desde sua fundação no ano de 2000 pela Dra.
          Gisele Romagna Stocco. Especializada em psiquiatria, a clínica foi concebida com uma visão clara e apaixonada:
          oferecer atendimento de saúde de alta qualidade e humanizado para seus pacientes.
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AboutTextComponent',
};
</script>
